import { useFormikContext } from 'formik';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Icon, Text } from '@attentive/picnic';

import { IntegrationFormValues, IntegrationFormFeatureValues } from '../../types';
import { customerDataPath } from '../../utils/routes';
import { IntegrationFeature, IntegrationFeatureProps } from '../IntegrationFeature';

type Props = IntegrationFeatureProps & {
  companyVendorIntegrationId?: number;
  children?: never;
};

export const BridgeIdentifierFeatureToggle: React.FC<Props> = ({
  companyVendorIntegrationId,
  ...featureProps
}) => {
  const { initialValues } = useFormikContext();
  const previouslyEnabled = (
    (initialValues as IntegrationFormValues).BRIDGE_IDENTIFIERS as IntegrationFormFeatureValues
  ).enabled;

  return (
    <IntegrationFeature {...featureProps}>
      {companyVendorIntegrationId && (
        <Box>
          {previouslyEnabled ? (
            <Button
              as={RouterLink}
              to={customerDataPath(companyVendorIntegrationId)}
              variant="basic"
              size="small"
              css={{ fontSize: '$fontSize2' }}
            >
              Edit segmentation
              <Icon
                name="Pencil"
                description="Edit Font"
                size="extraSmall"
                color="default"
                css={{ marginLeft: '$space2' }}
              />
            </Button>
          ) : (
            <Text color="subdued" variant="caption">
              Enable this option to edit segmentation
            </Text>
          )}
        </Box>
      )}
    </IntegrationFeature>
  );
};
