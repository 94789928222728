import React from 'react';

import ometriaLogo from '../assets/vendor_logos/ometria.png';
import { IntegrationField } from '../components/IntegrationField';
import { OmetriaSettingsForm } from '../components/SettingsStandard/OmetriaSettingsForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorOmetria: Vendor = {
  logo: ometriaLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://ometria.com/',
  salesEmail: 'partnerships@ometria.com',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Ometria.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Ometria so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['An Ometria account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: 'Automatically share subscriber info with Ometria.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4401930814996',
      label: 'Ometria integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_OMETRIA}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'collection',
            type: IntegrationFieldType.TEXT,
            label: 'Collection',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List IDs',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return <OmetriaSettingsForm onComplete={onComplete} />;
  },
  creativeSpecificLists: { type: 'compact', key: CreativeSpecificDataKeyNames.LISTS },
};
