import React from 'react';

import eloquaLogo from '../assets/vendor_logos/eloqua.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorEloqua: Vendor = {
  logo: eloquaLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.oracle.com/cx/marketing/automation/',
  infoPagesEnabled: true,
  learnSubtext: 'Grow your email list through Attentive sign-up forms and sync contacts to Eloqua.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Eloqua and can trigger a welcome email to new email marketing subscribers. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['An Eloqua account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        'Pass email addresses and phone numbers of subscribers collected through the Attentive sign-up unit to Eloqua. If the subscriber exists in the Eloqua platform, Attentive updates the user’s profile with the new contact information. If the subscriber doesn’t exist in Eloqua, a new profile is created for the user with the contact information.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4407674290324',
      label: 'Eloqua integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_ELOQUA}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'clientName',
            type: IntegrationFieldType.TEXT,
            label: 'Eloqua Client Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'region',
            type: IntegrationFieldType.SELECT,
            label: 'Eloqua Region',
            required: true,
            options: [
              {
                name: 'p01',
                value: 'p01',
              },
              {
                name: 'p02',
                value: 'p02',
              },
              {
                name: 'p03',
                value: 'p03',
              },
              {
                name: 'p04',
                value: 'p04',
              },
              {
                name: 'p06',
                value: 'p06',
              },
              {
                name: 'p07',
                value: 'p07',
              },
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_ELOQUA}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'clientName',
            type: IntegrationFieldType.TEXT,
            label: 'Eloqua Client Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'region',
            type: IntegrationFieldType.SELECT,
            label: 'Eloqua Region',
            required: true,
            options: [
              {
                name: 'p01',
                value: 'p01',
              },
              {
                name: 'p02',
                value: 'p02',
              },
              {
                name: 'p03',
                value: 'p03',
              },
              {
                name: 'p04',
                value: 'p04',
              },
              {
                name: 'p06',
                value: 'p06',
              },
              {
                name: 'p07',
                value: 'p07',
              },
            ],
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'FEATURE_TRIGGER_VENDOR_EMAIL_SEND',
            label: 'Trigger email send',
            subtext: 'Trigger email send within Eloqua',
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 'emailId',
                type: IntegrationFieldType.TEXT,
                label: 'Email ID',
                required: true,
              }),
              IntegrationField.Config({
                name: 'name',
                type: IntegrationFieldType.TEXT,
                label: 'Name',
                placeholder: 'Attentive',
                required: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_POST_SUBSCRIBER_OPT_OUT',
            label: 'Send SMS Status to Eloqua',
            subtext:
              "Send a subscriber's SMS opt in status as a boolean variable to a custom field ID",
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 'smsConsentFieldId',
                type: IntegrationFieldType.TEXT,
                label: 'Custom SMS Consent Field ID',
                required: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_POSTBACK_USER_ATTRIBUTES',
            label: 'Add custom properties to postback',
            subtext:
              'Include Zip code in the postback. This will also add custom fields if they are configured in the metadata',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
