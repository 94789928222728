/**
 * @generated SignedSource<<83509380e50bac939103d8077294ed99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreativeSpecificLists_IntegrationsCommon_SignUpUnitsCell_Query_Fragment$data = {
  readonly creatives: ReadonlyArray<{
    readonly displayName?: string;
    readonly externalName?: string;
    readonly id: string;
    readonly internalId?: string;
  } | null>;
  readonly " $fragmentType": "CreativeSpecificLists_IntegrationsCommon_SignUpUnitsCell_Query_Fragment";
};
export type CreativeSpecificLists_IntegrationsCommon_SignUpUnitsCell_Query_Fragment$key = {
  readonly " $data"?: CreativeSpecificLists_IntegrationsCommon_SignUpUnitsCell_Query_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreativeSpecificLists_IntegrationsCommon_SignUpUnitsCell_Query_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "creativeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativeSpecificLists_IntegrationsCommon_SignUpUnitsCell_Query_Fragment",
  "selections": [
    {
      "alias": "creatives",
      "args": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "creativeIds"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "externalName",
              "storageKey": null
            }
          ],
          "type": "Creative",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c1dd59a980aa05d9445eb567a4211576";

export default node;
