import { useField, useFormikContext } from 'formik';
import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { clientUIFeatureFlagsAtom, CompanyFeatureFlagNames } from '@attentive/acore-utils';
import { Box, PicnicCss } from '@attentive/picnic';

import { IntegrationFieldType } from '../../types';

import { IntegrationCheckboxField } from './IntegrationCheckboxField';
import { IntegrationIntegerField } from './IntegrationIntegerField';
import { IntegrationMultilineField } from './IntegrationMultilineField';
import { IntegrationPasswordField } from './IntegrationPasswordField';
import { IntegrationSelectField } from './IntegrationSelectField';
import { IntegrationTextField } from './IntegrationTextField';

type IntegrationFieldProps = {
  name: string;
  type: IntegrationFieldType;
  label: React.ReactNode;
  subtext?: string;
  placeholder?: string;
  options?: Array<{ name: string; value: string }> | undefined;
  defaultValue?: string;
  required: boolean;
  disabled?: boolean;
  copyable?: boolean;
  css?: PicnicCss;
  dataIntegrationsId?: string;
  featureFlag?: CompanyFeatureFlagNames;
};

export interface IntegrationFieldConfig {
  (propOverrides?: Partial<IntegrationFieldProps>): JSX.Element;
  componentProps: IntegrationFieldProps;
}

const IntegrationFieldComponent: React.FC<IntegrationFieldProps> = ({
  name,
  type,
  label,
  subtext,
  placeholder,
  options,
  defaultValue,
  required,
  disabled,
  copyable,
  css,
  dataIntegrationsId,
}) => {
  const { isSubmitting } = useFormikContext();
  const [{ onChange, onBlur, value }, { touched, error }, { setValue }] = useField(name);

  if (value === '' && defaultValue) {
    setValue(defaultValue);
  }

  if (type === IntegrationFieldType.SELECT) {
    return (
      <Box css={{ ...css }}>
        <IntegrationSelectField
          label={label}
          subtext={subtext}
          required={required}
          placeholder={placeholder || 'Select One'}
          options={options ?? []}
          error={(touched && error) || undefined}
          disabled={disabled || isSubmitting}
          onChange={setValue}
          {...{ onBlur, value, dataIntegrationsId }}
        />
      </Box>
    );
  }

  if (type === IntegrationFieldType.CHECKBOX) {
    return (
      <Box css={{ ...css }}>
        <IntegrationCheckboxField
          label={label}
          subtext={subtext}
          required={required}
          error={(touched && error) || undefined}
          disabled={disabled || isSubmitting}
          onChange={setValue}
          {...{ value, name, dataIntegrationsId }}
        />
      </Box>
    );
  }

  let ComponentFieldType;
  if (type === IntegrationFieldType.MULTILINE) {
    ComponentFieldType = IntegrationMultilineField;
  } else if (type === IntegrationFieldType.PASSWORD) {
    ComponentFieldType = IntegrationPasswordField;
  } else if (type === IntegrationFieldType.INT) {
    ComponentFieldType = IntegrationIntegerField;
  } else {
    ComponentFieldType = IntegrationTextField;
  }

  return (
    <Box css={{ ...css }}>
      <ComponentFieldType
        label={label}
        subtext={subtext}
        required={required}
        placeholder={placeholder || ''}
        error={(touched && error) || undefined}
        disabled={disabled || isSubmitting}
        copyable={Boolean(copyable)}
        {...{ onChange, onBlur, value, name, dataIntegrationsId }}
      />
    </Box>
  );
};

const FeatureFlaggedIntegrationFieldComponent: FC<IntegrationFieldProps> = ({
  featureFlag,
  ...rest
}) => {
  const featureFlags = useAtomValue(clientUIFeatureFlagsAtom);
  if (
    !!featureFlag &&
    (!(featureFlag in featureFlags.company) || !featureFlags.company[featureFlag])
  ) {
    return null;
  }

  return <IntegrationFieldComponent {...rest} />;
};

const IntegrationField = FeatureFlaggedIntegrationFieldComponent as CompositeComponent;

const Config = (props: IntegrationFieldProps) => {
  const renderField = (propOverrides: IntegrationFieldProps) => {
    const mergedProps = { ...props, ...propOverrides };
    return <IntegrationField {...mergedProps} key={mergedProps.name} css={{ width: '100%' }} />;
  };

  Object.assign(renderField, { componentProps: props });

  return renderField as IntegrationFieldConfig;
};

type ComponentType = typeof IntegrationFieldComponent;
interface CompositeComponent extends ComponentType {
  Config: (props: IntegrationFieldProps) => IntegrationFieldConfig;
}

IntegrationField.Config = Config;

export { IntegrationField };
