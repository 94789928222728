import { logError } from './logger';

function parseJson<T>(jsonStr: string | undefined | null, fallback: T) {
  let data: T = fallback;
  try {
    data = JSON.parse(jsonStr || JSON.stringify(fallback));
  } catch (e) {
    logError(e);
  }

  return data;
}

export { parseJson };
