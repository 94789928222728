import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

const metaAdsFields = [
  IntegrationField.Config({
    name: 'accessToken',
    type: IntegrationFieldType.PASSWORD,
    label: 'Access Token',
    placeholder: 'Enter access token',
    required: true,
  }),
  IntegrationField.Config({
    name: 'pixelId',
    type: IntegrationFieldType.TEXT,
    label: 'Pixel Id',
    placeholder: 'the pixel id',
    required: true,
  }),
];

export const vendorMetaAds: Vendor = {
  logo: placeholderLogo,
  category: VendorCategory.ANALYTICS,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_META_ADS}
        onComplete={onComplete}
        fields={metaAdsFields}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_META_ADS}
        onComplete={onComplete}
        fields={metaAdsFields}
      />
    );
  },
};
