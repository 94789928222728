import React from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { IntegrationDetailsResponse, VendorKey } from '../../../types';
import { SettingsStandard } from '../SettingsStandard';

import { OmetriaSettingsFormLegacy } from './OmetriaSettingsFormLegacy';
import { OmetriaSettingsFormNext } from './OmetriaSettingsFormNext';

function OmetriaSettingsForm({
  onComplete,
}: {
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}) {
  const isOmetriaRichSettingsEnabled = useCompanyFeatureFlag('ENABLE_OMETRIA_RICH_SETTINGS');

  return isOmetriaRichSettingsEnabled ? (
    <SettingsStandard
      vendorKey={VendorKey.VENDOR_OMETRIA}
      onComplete={onComplete}
      form={({ onFormComplete }) => <OmetriaSettingsFormNext onComplete={onFormComplete} />}
    />
  ) : (
    <OmetriaSettingsFormLegacy onComplete={onComplete} />
  );
}

export { OmetriaSettingsForm };
