import copyToClipboard from 'copy-to-clipboard';
import { FormikHandlers } from 'formik';
import React, { useCallback, useState } from 'react';

import { InputGroup, TextInput } from '@attentive/picnic';

import { FormField } from '../../FormField';

type IntegrationTextFieldProps = {
  required: boolean;
  name: string;
  label: React.ReactNode;
  subtext?: string;
  error?: string;
  value?: string;
  placeholder?: string;
  disabled: boolean;
  copyable: boolean;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  dataIntegrationsId?: string;
};

export const IntegrationTextField: React.FC<IntegrationTextFieldProps> = ({
  required,
  name,
  label,
  subtext,
  error,
  value,
  placeholder,
  disabled,
  copyable,
  onChange,
  onBlur,
  dataIntegrationsId,
}) => {
  const [hasClicked, setHasClicked] = useState(false);
  const copyValue = useCallback(() => {
    copyToClipboard(value || '');
    setHasClicked(true);
  }, [value]);

  return (
    <FormField required={required} label={label} name={name} subtext={subtext} error={error}>
      <InputGroup>
        <TextInput
          id={name}
          value={value}
          placeholder={placeholder}
          type="text"
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          state={error ? 'error' : 'normal'}
          data-integrations-id={dataIntegrationsId}
        />
        {copyable && (
          <InputGroup.RightElement>
            {hasClicked ? (
              'Copied!'
            ) : (
              <InputGroup.InlineButton type="button" onClick={copyValue}>
                Copy
              </InputGroup.InlineButton>
            )}
          </InputGroup.RightElement>
        )}
      </InputGroup>
    </FormField>
  );
};
