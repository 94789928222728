/**
 * @generated SignedSource<<8776694d8e9396c18cb4ba68d1178b3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query$variables = {
  companyId: string;
  creativeIds: ReadonlyArray<string>;
  searchTerm?: string | null;
};
export type CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query$data = {
  readonly company: {
    readonly creativesV2?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"CreativesSearchDialog_IntegrationsCommon_Creative_Fragment">;
        };
      }>;
    } | null;
  } | null;
  readonly creatives: ReadonlyArray<{
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreativesSearchDialog_IntegrationsCommon_Creative_Fragment">;
  } | null>;
};
export type CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query = {
  response: CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query$data;
  variables: CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creativeIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "creativeIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CreativesSearchDialog_IntegrationsCommon_Creative_Fragment"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "exclude",
        "value": {
          "archived": true,
          "typeV2": [
            "CREATIVE_TYPE_LEGAL"
          ]
        }
      },
      {
        "kind": "Literal",
        "name": "include",
        "value": {
          "statusLabel": [
            "CREATIVE_STATUS_LABEL_ACTIVE",
            "CREATIVE_STATUS_LABEL_INACTIVE",
            "CREATIVE_STATUS_LABEL_DRAFT"
          ],
          "userView": "LIST_CREATIVES_FILTER_USER_VIEW_SUPERUSER"
        }
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      {
        "sortDirection": "CREATIVES_SORT_DIRECTION_DESC",
        "sortField": "CREATIVES_SORT_FIELD_NAME"
      }
    ]
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusLabel",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query",
    "selections": [
      {
        "alias": "creatives",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v3/*: any*/),
            "type": "Creative",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "creativesV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreativeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Creative",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query",
    "selections": [
      {
        "alias": "creatives",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "Creative",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "company",
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "creativesV2",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreativeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Creative",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6293584c407d1f86b151887147b3449e",
    "id": null,
    "metadata": {},
    "name": "CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query",
    "operationKind": "query",
    "text": "query CreativesSearchDialog_IntegrationsCommon_CreativesSearchResults_Query(\n  $companyId: ID!\n  $creativeIds: [ID!]!\n  $searchTerm: String\n) {\n  creatives: nodes(ids: $creativeIds) {\n    __typename\n    ... on Creative {\n      id\n      ...CreativesSearchDialog_IntegrationsCommon_Creative_Fragment\n    }\n    id\n  }\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      creativesV2(first: 20, sort: [{sortField: CREATIVES_SORT_FIELD_NAME, sortDirection: CREATIVES_SORT_DIRECTION_DESC}], filter: {searchTerm: $searchTerm, exclude: {archived: true, typeV2: [CREATIVE_TYPE_LEGAL]}, include: {statusLabel: [CREATIVE_STATUS_LABEL_ACTIVE, CREATIVE_STATUS_LABEL_INACTIVE, CREATIVE_STATUS_LABEL_DRAFT], userView: LIST_CREATIVES_FILTER_USER_VIEW_SUPERUSER}}) {\n        edges {\n          node {\n            id\n            ...CreativesSearchDialog_IntegrationsCommon_Creative_Fragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment CreativesSearchDialog_IntegrationsCommon_Creative_Fragment on Creative {\n  id\n  internalId\n  displayName\n  statusLabel\n}\n"
  }
};
})();

(node as any).hash = "2b5c8699cd0390197756c8f9f12fbcca";

export default node;
