import { MOCK_API_BASE_URL } from '@attentive/acore-utils';
import { mswRest, parseMSWHeaders, mswGraphql, createNode } from '@attentive/mock-data';

import {
  Integration,
  IntegrationDetailsResponse,
  GoogleContentApiSetupData,
  GoogleTagManagerAccount,
  GoogleTagManagerContainer,
  GoogleTagManagerWorkspace,
  GoogleAdsSetupData,
} from '../types';

import {
  useSetMilestoneMutation$rawResponse,
  useSetMilestoneMutation$variables,
} from '../hooks/__generated__/useSetMilestoneMutation.graphql';

export interface ApiMSWHeaders {
  vendors?: Integration[];
  integration?: IntegrationDetailsResponse;
  connectIntegrationResponse?: IntegrationDetailsResponse;
  configureIntegrationResponse?: IntegrationDetailsResponse;
  settingsIntegrationResponse?: IntegrationDetailsResponse;
  googleAdsSetupManagerAccountData?: GoogleAdsSetupData;
  googleAdsSetupChildAccountData?: GoogleAdsSetupData;
  googleContentApiSetupData?: GoogleContentApiSetupData;
  googleTagManagerAccounts?: GoogleTagManagerAccount[];
  googleTagManagerContainers?: GoogleTagManagerContainer[];
  googleTagManagerWorkspaces?: GoogleTagManagerWorkspace[];
  oAuthLink?: string;
}

const API_URL = MOCK_API_BASE_URL;

const fetchVendors = mswRest.get(`${API_URL}/integrations`, (req, res, ctx) => {
  const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
  return res(ctx.json({ externalVendorApis: mswHeaders.vendors ?? [] }));
});

const fetchIntegration = mswRest.get(
  `${API_URL}/integrations/vendors/:vendor/integration`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.integration ?? null));
  }
);

const fetchGoogleAdsSetupData = mswRest.get(
  `${API_URL}/integrations/gads/setup`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    if (req.url.searchParams.get('account') === '5698901020') {
      return res(ctx.json({ accounts: [] }));
    }

    if (req.url.searchParams.get('account') === '1234567890') {
      return res(ctx.json(mswHeaders.googleAdsSetupChildAccountData ?? { accounts: [] }));
    }

    return res(ctx.json(mswHeaders.googleAdsSetupManagerAccountData ?? { accounts: [] }));
  }
);

const fetchGoogleContentAPISetupData = mswRest.get(
  `${API_URL}/integrations/google-content-api/setup`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.googleContentApiSetupData ?? { merchantIds: [] }));
  }
);

const fetchGoogleTagManagerAccount = mswRest.get(
  `${API_URL}/integrations/gtm/accounts`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.googleTagManagerAccounts ?? []));
  }
);

const fetchGoogleTagManagerContainer = mswRest.get(
  `${API_URL}/integrations/gtm/containers`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.googleTagManagerContainers ?? []));
  }
);

const fetchGoogleTagManagerWorkspace = mswRest.get(
  `${API_URL}/integrations/gtm/workspaces`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.googleTagManagerWorkspaces ?? []));
  }
);

const fetchOAuthLink = mswRest.get(
  `${API_URL}/integrations/oauth/:vendor/auth-link`,
  (req, res, ctx) => {
    const state = req.url.searchParams.get('state');
    const params = state ? `?${new URLSearchParams({ state })}` : '';

    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json({ link: `${mswHeaders.oAuthLink}${params}` }));
  }
);

const connectIntegration = mswRest.post(
  `${API_URL}/integrations/vendors/:vendor/connections`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.connectIntegrationResponse ?? null));
  }
);

const configureIntegration = mswRest.post(
  `${API_URL}/integrations/vendors/:vendor/configurations`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.configureIntegrationResponse ?? null));
  }
);

const updateIntegrationSettings = mswRest.put(
  `${API_URL}/integrations/vendors/:vendor/settings`,
  (req, res, ctx) => {
    const mswHeaders = parseMSWHeaders<ApiMSWHeaders>(req.headers);
    return res(ctx.json(mswHeaders.settingsIntegrationResponse ?? null));
  }
);

const disconnectIntegration = mswRest.post(
  `${API_URL}/integrations/vendors/:vendor/disconnections`,
  (_req, res, _ctx) => {
    return res();
  }
);

const mailchimpAudiences = mswRest.get(
  `${API_URL}/integrations/mailchimp/audiences`,
  (_req, res, ctx) => {
    return res(ctx.json([]));
  }
);

const completeMilestoneMutation = mswGraphql.mutation<
  useSetMilestoneMutation$rawResponse,
  useSetMilestoneMutation$variables
>('useSetMilestoneMutation', async (req, res, ctx) =>
  res(
    ctx.data({
      completeOnboardingMilestones: {
        company: createNode('Company', {
          id: req.variables.input.companyId || '',
          attentiveDomain: 'attn.com',
          milestones: null,
          onboardingSurveyOptions: null,
        }),
      },
    })
  )
);

export const handlers = [
  fetchVendors,
  fetchIntegration,
  fetchOAuthLink,
  connectIntegration,
  configureIntegration,
  updateIntegrationSettings,
  disconnectIntegration,
  fetchGoogleAdsSetupData,
  fetchGoogleContentAPISetupData,
  fetchGoogleTagManagerAccount,
  fetchGoogleTagManagerContainer,
  fetchGoogleTagManagerWorkspace,
  mailchimpAudiences,
  completeMilestoneMutation,
];
