import { useCurrentCompanyId } from '@attentive/acore-utils';
import { useQuery, useMutation } from '@attentive/data/react-query';
import { cloneDeep } from '@attentive/nodash';

import { IntegrationSettingsRequest, VendorKey } from '../types';
import { decodeGraphqlId } from '../utils/gql-id';
import { isTruthy } from '../utils/is-truthy';
import { parseJson } from '../utils/parse-json';

import {
  fetchIntegration,
  fetchVendors,
  fetchOAuthLink,
  connectIntegration,
  configureIntegration,
  updateIntegrationSettings,
  disconnectIntegration,
  NO_INTEGRATION_RESULTS,
  NO_VENDOR_RESULTS,
} from './integrations';

const FETCH_INTEGRATION_QUERY = 'FETCH_INTEGRATION_QUERY';
const FETCH_VENDORS_QUERY = 'FETCH_VENDORS_QUERY';

export const useIntegrationQuery = (vendor: VendorKey) => {
  const companyId = useCurrentCompanyId();
  const results = useQuery([FETCH_INTEGRATION_QUERY, companyId, vendor], () =>
    fetchIntegration(vendor)
  );
  const resultsData = results.data === NO_INTEGRATION_RESULTS ? undefined : results.data;
  return { ...results, data: resultsData };
};

export const useVendorsQuery = () => {
  const companyId = useCurrentCompanyId();
  const queryResults = useQuery([FETCH_VENDORS_QUERY, companyId], () => fetchVendors());
  const queryResultData = queryResults.data === NO_VENDOR_RESULTS ? undefined : queryResults.data;
  return { ...queryResults, data: queryResultData };
};

export const useOAuthLinkMutation = () => useMutation(fetchOAuthLink);

export const useConnectIntegrationMutation = () => useMutation(connectIntegration);

export const useConfigureIntegrationMutation = () => useMutation(configureIntegration);

export const useUpdateIntegrationSettingsMutation = () => useMutation(updateIntegrationSettings);

export const useUpdateIntegrationSettingsWithSpecialCasesMutation = () =>
  useMutation(({ vendor, payload }: { vendor: string; payload: IntegrationSettingsRequest }) => {
    const modifiedPayload = cloneDeep(payload);

    // The server expects a payload in the shape of Record<string, string[]>,
    // but on the client we work with entries, Array<[string, string[]]>. This
    // is because we need to preserve ordering and objects give no guarantees
    // about order. This isn't perfect because it's possible the keys will
    // change order as a result of saving during this translation, but this
    // at least keeps ordering consistent while the user is actively modifying
    // the data during their session.
    if ('creativeSpecificSources' in payload.fields) {
      const parsedValue = parseJson<Array<[string, string[]]>>(
        payload.fields.creativeSpecificSources,
        []
      );

      const decodedData = parsedValue.map(([key, ids]) => [
        key,
        ids.map((id) => decodeGraphqlId(id)?.internalId).filter(isTruthy),
      ]);
      modifiedPayload.fields.creativeSpecificSources = JSON.stringify(
        Object.fromEntries(decodedData)
      );
    }

    // Same as `creativeSpecificSources` above.
    if ('creativeSpecificLists' in payload.fields) {
      const parsedValue = parseJson<Array<[string, string[]]>>(
        payload.fields.creativeSpecificLists,
        []
      );
      const decodedData = parsedValue.map(([key, ids]) => [
        key,
        ids.map((id) => decodeGraphqlId(id)?.internalId).filter(isTruthy),
      ]);
      modifiedPayload.fields.creativeSpecificLists = JSON.stringify(
        Object.fromEntries(decodedData)
      );
    }

    // Same as `creativeSpecificSources` above, though slightly different
    // target data structure of Record<string, string>.
    if ('customFields' in payload.fields) {
      const parsedValue = parseJson<Array<[string, string]>>(payload.fields.customFields, []);

      modifiedPayload.fields.customFields = JSON.stringify(Object.fromEntries(parsedValue));
    }

    // Same as `customFields` above
    if ('customProperties' in payload.fields) {
      const parsedValue = parseJson<Array<[string, string]>>(payload.fields.customProperties, []);

      modifiedPayload.fields.customProperties = JSON.stringify(Object.fromEntries(parsedValue));
    }

    return updateIntegrationSettings({ vendor, payload: modifiedPayload });
  });

export const useDisconnectIntegrationMutation = () => useMutation(disconnectIntegration);
