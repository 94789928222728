import { Formik, FormikHelpers, useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useState, useMemo } from 'react';
import { object, string } from 'yup';

import {
  Box,
  Button,
  Icon,
  Text,
  StandardDialog,
  Heading,
  Stack,
  Card,
  IconButton,
} from '@attentive/picnic';

import { IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';

type Values = {
  name: string;
  description: string;
  url: string;
  deleted?: number | undefined;
  index: number | undefined;
};

type List = Omit<Values, 'index'>;

const PU_DIALOG_WIDTH = 544;
const PU_SEGMENT_CARD_WIDTH = 463;
const ADDING = undefined;
const FEATURE_TYPE = 'SYNC_THIRD_PARTY_LISTS';

export const CreateSegmentsFeature: FC = () => {
  const [{ value: listsValue }, , { setValue: setListsValue }] = useField(`${FEATURE_TYPE}.lists`);
  const [, , { setValue: setEnabledValue }] = useField(`${FEATURE_TYPE}.enabled`);

  const lists = useMemo<List[]>(() => (listsValue ? JSON.parse(listsValue) : []), [listsValue]);

  const [open, setOpen] = useState(false);

  const saveSegment = useCallback(
    (values: Values, _formikHelpers: FormikHelpers<Values>) => {
      const { name, description, url, index } = values;

      const listsCopy = lists.slice();
      if (index === ADDING) {
        listsCopy.push({ name, description, url });
      } else {
        listsCopy.splice(index, 1, { name, description, url });
      }
      setListsValue(JSON.stringify(listsCopy));

      setEnabledValue(true);
      setOpen(false);
    },
    [lists, setEnabledValue, setListsValue, setOpen]
  );

  const deleteSegment = useCallback(
    (index: number) => {
      const newList = lists.map((list, i) => {
        if (i === index) {
          return { ...list, deleted: Math.floor(new Date().getTime() / 1000) };
        }

        return list;
      });

      setListsValue(JSON.stringify(newList));
      setEnabledValue(!isEmpty(newList.filter((list) => !list.deleted)));
    },
    [lists, setListsValue, setEnabledValue]
  );

  return (
    <Formik
      initialValues={{ name: '', description: '', url: '', index: ADDING } as Values}
      validationSchema={object({
        name: string().required('This field is required.'),
        description: string().required('This field is required.'),
        url: string().required('This field is required.'),
      })}
      onSubmit={saveSegment}
    >
      {({ submitForm, resetForm, setValues, values }) => {
        const isAdding = values.index === ADDING;

        return (
          <>
            <Box>
              {lists
                .filter((list) => !list.deleted)
                .map(({ name, description, url }, index) => (
                  <Card
                    key={url}
                    css={{
                      mt: '$space4',
                      p: '$space3',
                      width: PU_SEGMENT_CARD_WIDTH,
                      display: 'flex',
                      justifyContent: 'space-between',
                      boxShadow: 'none',
                    }}
                    aria-label="Configured Segment"
                  >
                    <Box css={{ display: 'flex', alignItems: 'start' }}>
                      <Box
                        css={{
                          backgroundColor: '$bgInverted',
                          borderRadius: '100%',
                          lineHeight: '0',
                          p: '$space2',
                        }}
                      >
                        <Icon name="Segments" color="inverted" css={{ flexShrink: 0 }} />
                      </Box>

                      <Box css={{ ml: '$space3' }}>
                        <Heading variant="sm">{name}</Heading>
                        <Text variant="caption" color="subdued">
                          {description}
                        </Text>
                      </Box>
                    </Box>

                    <Box css={{ display: 'flex' }}>
                      <IconButton
                        variant="subdued"
                        size="small"
                        iconName="Pencil"
                        description="Edit Segment"
                        onClick={() => {
                          resetForm();
                          setValues({
                            name,
                            description,
                            url,
                            index,
                          });
                          setOpen(true);
                        }}
                      />
                      <IconButton
                        variant="subdued"
                        size="small"
                        iconName="Delete"
                        description="Delete Segment"
                        onClick={() => deleteSegment(index)}
                      />
                    </Box>
                  </Card>
                ))}

              <Button
                size="small"
                variant="subdued"
                css={{ mt: '$space4' }}
                onClick={() => {
                  resetForm();
                  setOpen(true);
                }}
              >
                <Icon size="small" name="PlusSign" />
                <Text css={{ pl: '$space2' }}>Add segment</Text>
              </Button>
            </Box>

            <StandardDialog open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
              <StandardDialog.Content
                css={{ width: PU_DIALOG_WIDTH }}
                aria-label={isAdding ? 'Add Segment Dialog' : 'Edit Segment Dialog'}
              >
                <StandardDialog.Header>
                  <StandardDialog.Heading>
                    {isAdding ? 'Add' : 'Edit'} segment
                  </StandardDialog.Heading>
                </StandardDialog.Header>
                <StandardDialog.Body>
                  <Stack direction="vertical" spacing="$space8">
                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="name"
                        label="Name"
                        required={true}
                        placeholder="e.g. New York Event Attendees"
                      />
                    </Box>

                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="description"
                        label="Description"
                        required={true}
                        placeholder="e.g. Subscribers that attended our New York event."
                      />
                    </Box>

                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="url"
                        label="Segment URL"
                        subtext="Links must be prepended with http:// or https://."
                        required={true}
                        placeholder="e.g. https://klaviyo.com/list/KJgTvZ"
                      />
                    </Box>
                  </Stack>
                </StandardDialog.Body>
                <StandardDialog.Footer>
                  <StandardDialog.Close onClick={() => setOpen(false)} variant="secondary">
                    Cancel
                  </StandardDialog.Close>

                  <Button onClick={submitForm}>{isAdding ? 'Add' : 'Update'} segment</Button>
                </StandardDialog.Footer>
              </StandardDialog.Content>
            </StandardDialog>
          </>
        );
      }}
    </Formik>
  );
};
