import React from 'react';

import { useRoles, useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Role } from '@attentive/data';
import { Link } from '@attentive/picnic';

import { IntegrationFeature } from '../IntegrationFeature';

import { BridgeIdentifierFeatureToggle } from './BridgeIdentifierFeatureToggle';
import { BridgeIdentifierSubfeature } from './BridgeIdentifierSubfeature';

export const SetupSuperuserFeatures: React.FC = () => {
  const isSuperUser = useRoles().has(Role.RoleSuperUser);
  const emailMarketingToggleEnabled = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');
  const emailMarketingCampaignToggleEnabled = useCompanyFeatureFlag('ENABLE_CAMPAIGN_EMAIL');

  if (!isSuperUser) {
    return null;
  }

  return (
    <>
      <IntegrationFeature
        type="FEATURE_SYNC_ABANDONED_CHECKOUTS"
        label="Sync Abandoned Checkouts"
        subtext="Sync Abandoned Checkouts"
      />

      <IntegrationFeature
        type="SYNC_PRODUCTS"
        label="Sync Product Data"
        subtext="Create targeted segments based on your Shopify product data."
      />

      <IntegrationFeature
        type="INGEST_TRANSACTIONAL_EVENTS"
        label="Ingest Transactional Events"
        subtext={
          <>
            In order to send messages based on transactional events, set up a journey{' '}
            <Link href="/journeys/list" target="_blank" rel="noopener noreferrer">
              here.
            </Link>{' '}
          </>
        }
      />

      <BridgeIdentifierSubfeature
        type="FEATURE_CREATE_MARKETING_SMS_SUBSCRIPTIONS"
        label="Collect SMS Marketing Subscribers at Checkout"
        subtext={
          <>
            ACTION REQUIRED: You must have an active Shopify sign-up unit assigned to a Welcome
            Journey to collect subscribers. Click{' '}
            <Link href="/subscribers/sign-up-units" target="_blank" rel="noopener noreferrer">
              here
            </Link>{' '}
            to complete signup.
          </>
        }
      />

      {(emailMarketingToggleEnabled || emailMarketingCampaignToggleEnabled) && (
        <BridgeIdentifierSubfeature
          type="FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS"
          label="Collect Email Marketing Subscribers"
          subtext={
            <>
              ACTION REQUIRED: You must have an active Shopify sign-up unit to collect subscribers.
              Although a Welcome Journey is not required to collect email subscribers, we recommend
              you setting one up. Click{' '}
              <Link href="/subscribers/sign-up-units" target="_blank" rel="noopener noreferrer">
                here
              </Link>{' '}
              to create a sign-up unit and optionally attach a Welcome Journey.
            </>
          }
        />
      )}

      <IntegrationFeature
        type="POSTBACK_EMAILS"
        label="Forward emails"
        subtext="Automatically add all emails collected by Attentive to your Shopify store"
      />

      <IntegrationFeature
        type="FEATURE_SYNC_SMS_CONSENT"
        label="Forward SMS data"
        subtext="Forward SMS marketing consent status to Shopify"
      />

      {(emailMarketingToggleEnabled || emailMarketingCampaignToggleEnabled) && (
        <IntegrationFeature
          type="FEATURE_SYNC_EMAIL_CONSENT"
          label="Forward Email data"
          subtext="Forward Email marketing consent status to Shopify"
        />
      )}

      <BridgeIdentifierFeatureToggle type="BRIDGE_IDENTIFIERS" label="Sync Customer Data" />

      <BridgeIdentifierSubfeature
        type="SYNC_HISTORICAL_PURCHASES"
        label="Sync Past Purchases"
        subtext="Create targeted segments based on your Shopify purchase data."
      />
    </>
  );
};
