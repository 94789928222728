import { FormikHandlers } from 'formik';
import React from 'react';

import { InputGroup, TextInput } from '@attentive/picnic';

import { FormField } from '../../FormField';

type IntegrationPasswordFieldProps = {
  required: boolean;
  name: string;
  label: React.ReactNode;
  subtext?: string;
  error?: string;
  value?: string;
  placeholder?: string;
  disabled: boolean;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  dataIntegrationsId?: string;
};

export const IntegrationPasswordField: React.FC<IntegrationPasswordFieldProps> = ({
  required,
  name,
  label,
  subtext,
  error,
  value,
  placeholder,
  disabled,
  onChange,
  onBlur,
  dataIntegrationsId,
}) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const toggleVisibility = () => setShowPassword(!showPassword);

  return (
    <FormField required={required} label={label} name={name} subtext={subtext} error={error}>
      <InputGroup>
        <TextInput
          id={name}
          value={value}
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          state={error ? 'error' : 'normal'}
          data-integrations-id={dataIntegrationsId}
        />

        <InputGroup.RightElement>
          <InputGroup.InlineButton type="button" onClick={toggleVisibility}>
            {showPassword ? 'Hide' : 'Show'}
          </InputGroup.InlineButton>
        </InputGroup.RightElement>
      </InputGroup>
    </FormField>
  );
};
