import { FormikHandlers } from 'formik';
import React from 'react';

import { Checkbox, FormField } from '@attentive/picnic';

type IntegrationTextFieldProps = {
  required: boolean;
  name: string;
  label: React.ReactNode;
  subtext?: string;
  error?: string;
  value?: string;
  disabled: boolean;
  onChange: FormikHandlers['handleChange'];
  dataIntegrationsId?: string;
};

export const IntegrationCheckboxField: React.FC<IntegrationTextFieldProps> = ({
  name,
  label,
  subtext,
  error,
  value,
  disabled,
  onChange,
}) => {
  return (
    <FormField layout="horizontal" css={{ display: 'block' }}>
      {subtext && (
        <FormField.HelperText css={{ margin: '$space2 0 0 $space5' }} color="subdued">
          {subtext}
        </FormField.HelperText>
      )}
      {error && <FormField.ErrorText>{error}</FormField.ErrorText>}
      <Checkbox
        id={name}
        name={name}
        checked={value === 'true'}
        onChange={(isChecked) => {
          onChange(isChecked ? 'true' : 'false');
        }}
        disabled={disabled}
      >
        {label}
      </Checkbox>
    </FormField>
  );
};
