import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { KlaviyoSettingsFormLegacy } from './KlaviyoSettingsFormLegacy';
import { KlaviyoSettingsFormNext } from './KlaviyoSettingsFormNext';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const KlaviyoSettingsForm: FC<Props> = (props) => {
  const isKlaviyoRichSettingsEnabled = useCompanyFeatureFlag('ENABLE_KLAVIYO_RICH_SETTINGS');

  return isKlaviyoRichSettingsEnabled ? (
    <KlaviyoSettingsFormNext {...props} />
  ) : (
    <KlaviyoSettingsFormLegacy {...props} />
  );
};
