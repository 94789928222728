import { useField } from 'formik';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { object, string } from 'yup';

import {
  Box,
  Button,
  ButtonBar,
  Checkbox,
  Dialog,
  Form,
  Heading,
  Icon,
  IconButton,
  Popover,
  Separator,
  Table,
  Text,
} from '@attentive/picnic';

import { parseJson } from '../../../utils/parse-json';

type AdditionalPropertyEntries = Array<[string, string]>;

const AVAILABLE_MACROS = {
  'Anonymous ID': 'ANON_ID',
  'Creative ID': 'CREATIVE_ID',
  Coupon: 'COUPON',
  Email: 'EMAIL',
  Phone: 'PHONE',
};

const additionalPropertySchema = object().shape({
  vendorValue: string().required('Field is required.'),
  attentiveValue: string().required('Field is required.'),
});

function AdditionalPropertyDialog({
  vendorName,
  vendorValue,
  attentiveValue,
  children,
  onSave,
}: PropsWithChildren<{
  vendorName: string;
  vendorValue: string;
  attentiveValue: string;
  onSave: (vendorValue: string, attentiveValue: string) => void;
}>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>
        <div>{children}</div>
      </Dialog.Trigger>
      <Dialog.Content css={{ width: '612px' }}>
        <Box>
          <Dialog.Header css={{ padding: '$space5 $space6' }}>
            <Heading variant="md">Add property</Heading>
          </Dialog.Header>
          <Separator />
          <Form
            validationSchema={additionalPropertySchema}
            initialValues={{ vendorValue, attentiveValue }}
            onSubmit={(values) => {
              onSave(values.vendorValue, values.attentiveValue);
              setIsOpen(false);
            }}
          >
            <Box css={{ px: '$space6', py: '$space6', overflowY: 'auto' }}>
              <Form.FormField name="vendorValue" css={{ mb: '$space6' }}>
                <Form.Label>{vendorName} property name</Form.Label>
                <Form.TextInput placeholder="Property name" />
              </Form.FormField>
              <Form.FormField name="attentiveValue" css={{ mb: '$space3' }}>
                <Form.Label>Attentive value</Form.Label>
                <Form.TextArea placeholder="Attentive value" />
              </Form.FormField>
              <Popover>
                <Popover.Trigger>
                  <IconButton
                    description="Available macros"
                    iconName="Brackets"
                    variant="basic"
                    size="small"
                  />
                </Popover.Trigger>
                <Popover.Content showCloseButton={false} css={{ width: 'fit-content' }}>
                  <Heading
                    variant="subheading"
                    color="subdued"
                    css={{ whiteSpace: 'nowrap', mb: '$space3' }}
                  >
                    Available {vendorName} Macros
                  </Heading>
                  <Box as="ul" css={{ m: 0, p: 0, listStyle: 'none' }}>
                    {Object.entries(AVAILABLE_MACROS).map(([label, macro]) => (
                      <Box key={label} as="li" css={{ mb: '$space2', '&:last-child': { mb: 0 } }}>
                        <Text variant="caption">{label}</Text>
                        <Text variant="caption" color="subdued">
                          {`$\{${macro}}`}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                </Popover.Content>
              </Popover>
            </Box>
            <Separator />
            <ButtonBar css={{ px: '$space6', py: '$space5' }} layout="stretch">
              <Dialog.Close variant="secondary">Cancel</Dialog.Close>
              <Form.SubmitButton>Submit</Form.SubmitButton>
            </ButtonBar>
          </Form>
        </Box>
      </Dialog.Content>
    </Dialog>
  );
}

function AdditionalPropertiesTable({
  name,
  vendorName,
  additionalProperties,
}: {
  name: string;
  vendorName: string;
  additionalProperties: AdditionalPropertyEntries;
}) {
  const [, , { setValue }] = useField(name);

  const handleChange = (value: AdditionalPropertyEntries) => {
    setValue(JSON.stringify(value));
  };

  const handleAdd = (vendorValue: string, attentiveValue: string) => {
    const nextEntries: AdditionalPropertyEntries = [
      ...additionalProperties,
      [vendorValue, attentiveValue],
    ];
    handleChange(nextEntries);
  };

  const handleDelete = (key: string) => {
    const nextEntries = additionalProperties.filter(([k]) => k !== key);
    handleChange(nextEntries);
  };

  const handleUpdate = (key: string, nextKey: string, nextValue: string) => {
    const nextEntries: AdditionalPropertyEntries = additionalProperties.map(([k, creativeIds]) => {
      if (k === key) {
        return [nextKey, nextValue];
      }
      return [k, creativeIds];
    });
    handleChange(nextEntries);
  };

  return (
    <>
      <Table columns={2} css={{ mb: '$space1' }}>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell>{vendorName} property name</Table.HeaderCell>
            <Table.HeaderCell>Attentive value</Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {additionalProperties.map(([vendorValue, attentiveValue], idx) => (
            // In this case we do actually want to use the index as a key
            // because the user can modify the source.
            // eslint-disable-next-line react/no-array-index-key
            <Table.BodyRow key={idx}>
              <Table.BodyCell>{vendorValue}</Table.BodyCell>
              <Table.BodyCell>
                {attentiveValue}
                <Box css={{ display: 'flex', alignItems: 'center', pl: '$space9', ml: 'auto' }}>
                  <AdditionalPropertyDialog
                    vendorName={vendorName}
                    vendorValue={vendorValue}
                    attentiveValue={attentiveValue}
                    onSave={(nextVendor, nextAttentive) => {
                      handleUpdate(vendorValue, nextVendor, nextAttentive);
                    }}
                  >
                    <IconButton
                      description="Edit"
                      iconName="Pencil"
                      variant="basic"
                      size="small"
                      css={{ mr: '$space1' }}
                    />
                  </AdditionalPropertyDialog>
                  <IconButton
                    description="Delete"
                    iconName="X"
                    variant="basic"
                    size="small"
                    onClick={() => {
                      handleDelete(vendorValue);
                    }}
                  />
                </Box>
              </Table.BodyCell>
            </Table.BodyRow>
          ))}
        </Table.Body>
      </Table>

      <AdditionalPropertyDialog
        vendorName={vendorName}
        vendorValue=""
        attentiveValue=""
        onSave={handleAdd}
      >
        <Button variant="subdued" size="small">
          <Icon name="PlusSign" size="extraSmall" css={{ mr: '$space2' }} /> Add additional property
        </Button>
      </AdditionalPropertyDialog>
    </>
  );
}

function AdditionalProperties({ name, vendorName }: { name: string; vendorName: string }) {
  const [{ value }, , { setValue }] = useField(name);
  const additionalProperties = useMemo(() => {
    return parseJson<AdditionalPropertyEntries>(value, []);
  }, [value]);
  const [isExpanded, setIsExpanded] = useState(additionalProperties.length > 0);

  const handleToggleProperties = (isChecked: boolean | string) => {
    if (!isChecked) {
      setValue(JSON.stringify([]));
    }

    setIsExpanded(!!isChecked);
  };

  return (
    <Box>
      <Checkbox id={name} name={name} checked={isExpanded} onChange={handleToggleProperties}>
        Add additional properties
      </Checkbox>
      {isExpanded && (
        <Box css={{ mt: '$space4', ml: '$space4' }}>
          <Heading variant="sm">Add additional properties</Heading>
          <Text variant="caption" css={{ mb: '$space4' }}>
            Add custom attribute data you would like to pass to {vendorName}
          </Text>
          <AdditionalPropertiesTable
            name={name}
            vendorName={vendorName}
            additionalProperties={additionalProperties}
          />
        </Box>
      )}
    </Box>
  );
}

export { AdditionalProperties };
