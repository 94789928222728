import { DistributionStatus, AppstoreApplication, Integration } from '../types';

export const isVisibleInMarketplace = (status: DistributionStatus) => {
  return status === DistributionStatus.BETA || status === DistributionStatus.PUBLIC;
};

export const isAppstoreApplication = (
  application: AppstoreApplication | Integration
): application is AppstoreApplication => {
  return 'applicationId' in application;
};
