import pick from 'lodash/pick';
import React, { useMemo } from 'react';

import { Accordion, Box, Text, TextInput } from '@attentive/picnic';

import { useUpdateIntegrationSettingsWithSpecialCasesMutation } from '../../../api';
import { useIntegrationContext } from '../../../hooks';
import { IntegrationFieldType, IntegrationFormFeatureValues } from '../../../types';
import { generateGraphqlId } from '../../../utils/gql-id';
import { parseJson } from '../../../utils/parse-json';
import { IntegrationField } from '../../IntegrationField';
import { IntegrationForm } from '../../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../../SetupConnectAndConfigure/SubmitButton';
import { AdditionalProperties } from '../controls/AdditionalProperties';
import { CreativeSpecificLists } from '../controls/CreativeSpecificLists';
import { CreativeSpecificSources } from '../controls/CreativeSpecificSources';

function AccordionHeader({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Accordion.Header>
      <Box>
        {title}
        <Text variant="caption" css={{ fontWeight: '$regular' }}>
          {subtitle}
        </Text>
      </Box>
    </Accordion.Header>
  );
}

function OmetriaSettingsFormNext({ onComplete }: { onComplete: () => void }) {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      listId: { required: true },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const rawInitialValues: Record<string, string | IntegrationFormFeatureValues> = pick(
    integrationDetailValues || {},
    [
      'apiKey',
      'collection',
      'listId',
      'creativeSpecificSources',
      'creativeSpecificLists',
      'customProperties',
      'POSTBACK_EMAILS',
      'POST_WITH_PHONE',
      'FEATURE_POST_SUBSCRIBER_OPT_OUT',
    ]
  );

  const initialValues = {
    ...rawInitialValues,
    // Server returns a record, but because we need to preserve ordering while
    // the user is editing these values, we need to convert it into entries.
    // The server also returns Creative internalIds and can't handle gql ids, so
    // we need to manually convert them as everything downstream of this uses
    // graphql. We essentially do the reverse of all of this when saving
    // back to the server. We translate ids back to internalIds and convert
    // the entries into an object.
    creativeSpecificSources: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificSources as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    // Same reasoning as `creativeSpecificSources`
    creativeSpecificLists: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificLists as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    // Same reasoning as `creativeSpecificSources`
    customProperties: useMemo(() => {
      const data = parseJson<Record<string, string>>(
        rawInitialValues.customProperties as string,
        {}
      );
      return JSON.stringify(Object.entries(data));
    }, [rawInitialValues]),
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsWithSpecialCasesMutation}
      onComplete={onComplete}
    >
      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="app-details">
          <AccordionHeader
            title="App details"
            subtitle="Configure high-level details about the app."
          />
          <Accordion.Content>
            <IntegrationField
              name="apiKey"
              type={IntegrationFieldType.PASSWORD}
              label="API Key"
              placeholder="Enter API Key"
              required={true}
              disabled={true}
              css={{ width: '100%', mb: '$space4' }}
            />
            <IntegrationField
              name="collection"
              type={IntegrationFieldType.TEXT}
              label="Collection"
              placeholder="Enter Collection"
              required={true}
              css={{ width: '100%', mb: '$space4' }}
            />
            <IntegrationField
              name="listId"
              type={IntegrationFieldType.TEXT}
              label="List IDs"
              placeholder="Enter List IDs"
              required={true}
              css={{ width: '100%' }}
            />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="source-management">
          <AccordionHeader
            title="Sign-up unit source management"
            subtitle="Specify the source given to emails from specific sign-up units."
          />
          <Accordion.Content>
            <CreativeSpecificSources name="creativeSpecificSources" />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="list-management">
          <AccordionHeader
            title="Sign-up unit list management"
            subtitle="Specify the list destination for emails from specific sign-up units."
          />
          <Accordion.Content>
            <CreativeSpecificLists
              name="creativeSpecificLists"
              listControl={({ value, onChange }) => (
                <TextInput
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
            />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
        <Accordion.Item value="data-sync">
          <AccordionHeader
            title="Sync additional data to Ometria"
            subtitle="Select additional data to sync to Ometria."
          />
          <Accordion.Content>
            <AdditionalProperties vendorName="Ometria" name="customProperties" />
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
}

export { OmetriaSettingsFormNext };
