import pick from 'lodash/pick';
import React, { FC, useMemo } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Accordion, Box, Text } from '@attentive/picnic';

import { useUpdateIntegrationSettingsWithSpecialCasesMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType, VendorKey } from '../../types';
import { generateGraphqlId } from '../../utils/gql-id';
import { parseJson } from '../../utils/parse-json';
import { IntegrationField } from '../IntegrationField';
import { ListField } from '../Klaviyo';
import { KlaviyoIdDialog } from '../Klaviyo/KlaviyoIdDialog';
import { ListControl } from '../Klaviyo/ListControl';
import { SyncAdditionalData } from '../Klaviyo/SyncAdditionalData';
import { SyncEmailListsFeature } from '../Klaviyo/SyncEmailListsFeature';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { CreateSegmentsFeature } from './CreateSegmentsFeature';
import { CreateUserAttributesFeature } from './CreateUserAttributesFeature';
import { CreativeSpecificLists } from './controls/CreativeSpecificLists';
import { CreativeSpecificSources } from './controls/CreativeSpecificSources';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

function AccordionHeader({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <Accordion.Header>
      <Box>
        {title}
        <Text variant="caption" css={{ fontWeight: '$regular' }}>
          {subtitle}
        </Text>
      </Box>
    </Accordion.Header>
  );
}

export const KlaviyoSettingsFormNext: FC<Props> = ({ onComplete, superUserEnabled }) => {
  const { integrationDetailValues } = useIntegrationContext();
  const enableTriggeredEmail = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      listId: { required: true },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const rawInitialValues: Record<string, string | IntegrationFormFeatureValues> = pick(
    integrationDetailValues || {},
    [
      'apiKey',
      'listId',
      'creativeSpecificSources',
      'creativeSpecificLists',
      'customFields',
      'postWithCoupon',
      'sendSubscriptionOptedOut',
      'postWithPhone',
      'FEATURE_EMAIL_OPT_OUT_SYNC',
      'FEATURE_POSTBACK_USER_ATTRIBUTES',
      'SYNC_THIRD_PARTY_LISTS',
      'FEATURE_SYNC_LIST_IDENTIFIERS',
      'POSTBACK_EMAILS',
    ]
  );

  const initialValues = {
    ...rawInitialValues,
    // the SYNC_THIRD_PARTY_LISTS feature is strange in that by default it has
    // a name, url and description fields that aren't real because the data is really
    // transmitted via the lists field which is serialized JSON, so only pick out
    // the lists field this feature.
    SYNC_THIRD_PARTY_LISTS: pick(rawInitialValues.SYNC_THIRD_PARTY_LISTS, ['enabled', 'lists']) as
      | string
      | IntegrationFormFeatureValues,
    // Server returns a record, but because we need to preserve ordering while
    // the user is editing these values, we need to convert it into entries.
    // The server also returns Creative internalIds and can't handle gql ids, so
    // we need to manually convert them as everything downstream of this uses
    // graphql. We essentially do the reverse of all of this when saving
    // back to the server. We translate ids back to internalIds and convert
    // the entries into an object.
    creativeSpecificSources: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificSources as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    // Same reasoning as `creativeSpecificSources`
    creativeSpecificLists: useMemo(() => {
      const data = parseJson<Record<string, string[]>>(
        rawInitialValues.creativeSpecificLists as string,
        {}
      );
      const encodedData = Object.entries(data).map(([key, internalIds]) => [
        key,
        internalIds.map((internalId) => generateGraphqlId('Creative', internalId)),
      ]);
      return JSON.stringify(encodedData);
    }, [rawInitialValues]),
    // Same reasoning as `creativeSpecificSources`
    customFields: useMemo(() => {
      const data = parseJson<Record<string, string>>(rawInitialValues.customFields as string, {});
      return JSON.stringify(Object.entries(data));
    }, [rawInitialValues]),
    postWithCoupon: rawInitialValues.postWithCoupon === 'true' ? 'true' : 'false',
    sendSubscriptionOptedOut:
      rawInitialValues.sendSubscriptionOptedOut === 'true' ? 'true' : 'false',
    postWithPhone: rawInitialValues.postWithPhone === 'true' ? 'true' : 'false',
  };

  return (
    <>
      <IntegrationForm
        loadingText="Saving"
        initialValues={initialValues}
        validationConfig={validationConfig}
        mutation={useUpdateIntegrationSettingsWithSpecialCasesMutation}
        onComplete={onComplete}
      >
        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="app-details">
            <AccordionHeader
              title="App details"
              subtitle="Configure high-level details about the app."
            />
            <Accordion.Content>
              <ListField
                label="Select the Klaviyo list where you want to push new subscribers to"
                css={{ mb: '$space4' }}
              />
              <IntegrationField
                name="apiKey"
                type={IntegrationFieldType.PASSWORD}
                label="API Key"
                placeholder="Enter API Key"
                required={true}
                disabled={true}
                css={{ width: '100%', mb: '$space4' }}
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="source-management">
            <AccordionHeader
              title="Sign-up unit source management"
              subtitle="Specify the source given to emails from specific sign-up units."
            />
            <Accordion.Content>
              <CreativeSpecificSources name="creativeSpecificSources" />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="list-management">
            <AccordionHeader
              title="Sign-up unit list management"
              subtitle="Specify the list destination for emails from specific sign-up units."
            />
            <Accordion.Content>
              <CreativeSpecificLists
                name="creativeSpecificLists"
                listControl={({ value, onChange }) => (
                  <ListControl value={value} onChange={onChange} />
                )}
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="user-attributes">
            <AccordionHeader
              title="Pass additional attributes to Klaviyo"
              subtitle="Attentive will update customer records in Klaviyo to include subscriber attributes collected via sign-up units, integration data, and conversational responses."
            />
            <Accordion.Content>
              <CreateUserAttributesFeature
                vendor={VendorKey.KLAVIYO}
                label="Sync additional attributes to Klaviyo"
                subtext=""
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="data-sync">
            <AccordionHeader
              title="Sync additional data to Klaviyo"
              subtitle="Select additional data to sync to Klaviyo."
            />
            <Accordion.Content>
              <SyncAdditionalData isSuperUser={!!superUserEnabled} />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        <Accordion collapsible type="single" variant="neutral" css={{ width: '100%' }}>
          <Accordion.Item value="segments">
            <AccordionHeader
              title="Create Segments"
              subtitle="Create Attentive segments by using your Klaviyo list URLs."
            />
            <Accordion.Content>
              <CreateSegmentsFeature />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>

        {superUserEnabled && enableTriggeredEmail && <SyncEmailListsFeature />}

        <SubmitButton text="Save changes" />
      </IntegrationForm>
      <KlaviyoIdDialog />
    </>
  );
};
