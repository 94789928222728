/**
 * @generated SignedSource<<84daf260e8b4474b236194978d7b781c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CreativeStatusLabel = "CREATIVE_STATUS_LABEL_ACTIVE" | "CREATIVE_STATUS_LABEL_DRAFT" | "CREATIVE_STATUS_LABEL_INACTIVE" | "CREATIVE_STATUS_LABEL_UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreativesSearchDialog_IntegrationsCommon_Creative_Fragment$data = {
  readonly displayName: string;
  readonly id: string;
  readonly internalId: string;
  readonly statusLabel: CreativeStatusLabel;
  readonly " $fragmentType": "CreativesSearchDialog_IntegrationsCommon_Creative_Fragment";
};
export type CreativesSearchDialog_IntegrationsCommon_Creative_Fragment$key = {
  readonly " $data"?: CreativesSearchDialog_IntegrationsCommon_Creative_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreativesSearchDialog_IntegrationsCommon_Creative_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreativesSearchDialog_IntegrationsCommon_Creative_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusLabel",
      "storageKey": null
    }
  ],
  "type": "Creative",
  "abstractKey": null
};

(node as any).hash = "e96f673ba246acf3e7bd12626eb9b5ff";

export default node;
