import React from 'react';

import { Banner, Text, Link } from '@attentive/picnic';

export const ShopifyTerms: React.FC = () => {
  return (
    <Banner variant="info">
      <Text variant="body" color="subdued">
        Enabling this integration authorizes bidirectional data sharing between Attentive and
        Shopify, including subscriber data (phone numbers and opt-in information). You have the
        option to disable sharing of specific data elements. Visit the{' '}
        <Link
          href="https://help.shopify.com/en/manual/promoting-marketing/create-marketing/customer-contact-information"
          target="_blank"
          rel="noopener noreferrer"
        >
          Shopify Help Center
        </Link>{' '}
        and these{' '}
        <Link
          href="https://docs.attentive.com/pages/shopify-subscriber-collection/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Attentive Terms
        </Link>{' '}
        for more details.
      </Text>
    </Banner>
  );
};
