import { logError } from './logger';

function generateGraphqlId(domain: String, id: string) {
  const formattedLength = `0${domain.length}`.slice(-2); // needs to be 2 digits
  const constructedString = `${formattedLength}:${domain}${id}`;
  return window.btoa(constructedString).replace(/=/g, '');
}

function decodeGraphqlId(graphId: string) {
  try {
    const decoded = window.atob(graphId);
    const domainLength = parseInt(decoded.slice(0, 2));
    const domain = decoded.slice(3, 3 + domainLength);
    const internalId = parseInt(decoded.slice(3 + domainLength));
    if (domain === '' || isNaN(internalId)) {
      throw new Error(`Invalid graphId: ${graphId}`);
    }
    return { domain, internalId };
  } catch (e) {
    logError(e);
  }

  return null;
}

export { generateGraphqlId, decodeGraphqlId };
