import React from 'react';

import snowflakeLogo from '../assets/vendor_logos/snowflake.png';
import { IntegrationField } from '../components';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const snowflake: Vendor = {
  logo: snowflakeLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  whatYoullNeedItems: ['Your Snowflake account identifier'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.LIST_GROWTH,
      description:
        'Create a holistic view of your customers across channels and platforms to better identify ways to grow your subscriber list',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Create a holistic view of your customers across channels and platforms to enable more refined segmentation strategies',
    },
  ],
  companyWebsite: 'https://snowflake.com/',
  infoPagesEnabled: true,
  learnSubtext: 'Make Attentive Event & Performance data available directly in Snowflake',
  jointValueProp:
    'Make Attentive event & performance data available directly in Snowflake using native data sharing.  Data sharing makes joining your Attentive customer engagement data with all your other Snowflake data easier than ever - creating richer insights across a single, reliable source of truth.',
  helpLinks: [
    {
      href: 'https://docs.snowflake.com/en/user-guide/admin-account-identifier#finding-the-organization-and-account-name-for-an-account',
      label: 'How to find your Account Identifier',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_SNOWFLAKE_DATA_SHARE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountIdentifier',
            type: IntegrationFieldType.TEXT,
            label: 'Account Identifier',
            required: true,
            placeholder: '<org_name>.<account_name>',
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SNOWFLAKE_DATA_SHARE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountIdentifier',
            type: IntegrationFieldType.TEXT,
            label: 'Account Identifier',
            disabled: true,
            required: true,
          }),
          IntegrationField.Config({
            name: 'tableName',
            type: IntegrationFieldType.TEXT,
            label: 'Table Name',
            disabled: true,
            required: true,
          }),
        ]}
      />
    );
  },
};
