/**
 * @generated SignedSource<<1861421981b6af22c98fc9b026fb388b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreativeSpecificSources_IntegrationsCommon_Query$variables = {
  creativeIds: ReadonlyArray<string>;
};
export type CreativeSpecificSources_IntegrationsCommon_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreativeSpecificSources_IntegrationsCommon_SignUpUnitsCell_Query_Fragment">;
};
export type CreativeSpecificSources_IntegrationsCommon_Query = {
  response: CreativeSpecificSources_IntegrationsCommon_Query$data;
  variables: CreativeSpecificSources_IntegrationsCommon_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creativeIds"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeSpecificSources_IntegrationsCommon_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CreativeSpecificSources_IntegrationsCommon_SignUpUnitsCell_Query_Fragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreativeSpecificSources_IntegrationsCommon_Query",
    "selections": [
      {
        "alias": "creatives",
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "creativeIds"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "internalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalName",
                "storageKey": null
              }
            ],
            "type": "Creative",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71ad946f7321be00b41dec9b715de6a9",
    "id": null,
    "metadata": {},
    "name": "CreativeSpecificSources_IntegrationsCommon_Query",
    "operationKind": "query",
    "text": "query CreativeSpecificSources_IntegrationsCommon_Query(\n  $creativeIds: [ID!]!\n) {\n  ...CreativeSpecificSources_IntegrationsCommon_SignUpUnitsCell_Query_Fragment\n}\n\nfragment CreativeSpecificSources_IntegrationsCommon_SignUpUnitsCell_Query_Fragment on Query {\n  creatives: nodes(ids: $creativeIds) {\n    __typename\n    id\n    ... on Creative {\n      internalId\n      displayName\n      externalName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd55c0122ff0ef36a47ad31649778fa2";

export default node;
