import { FormikHandlers } from 'formik';
import React from 'react';

import { InputGroup, TextArea } from '@attentive/picnic';

import { FormField } from '../../FormField';

type IntegrationMultilineFieldProps = {
  required: boolean;
  name: string;
  label: React.ReactNode;
  subtext?: string;
  error?: string;
  value?: string;
  placeholder?: string;
  disabled: boolean;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
  dataIntegrationsId?: string;
};

export const IntegrationMultilineField: React.FC<IntegrationMultilineFieldProps> = ({
  required,
  name,
  label,
  subtext,
  error,
  value,
  placeholder,
  disabled,
  onChange,
  onBlur,
  dataIntegrationsId,
}) => {
  return (
    <FormField required={required} label={label} name={name} subtext={subtext} error={error}>
      <InputGroup>
        <TextArea
          id={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          state={error ? 'error' : 'normal'}
          data-integrations-id={dataIntegrationsId}
        />
      </InputGroup>
    </FormField>
  );
};
