import { API } from '@attentive/acore-utils';

import { getErrorMessage } from '../api/utils';
import {
  AppstoreApplicationResponse,
  AppstoreApplicationDetails,
  FeaturedApplication,
  SalesContactRequest,
  ApplicationType,
} from '../types';

const COMPANY_APPLICATIONS_ENDPOINT = '/company-applications';
const APP_STORE_APPLICATIONS_ENDPOINT = '/appstore/applications';
const APP_STORE_FEATURED_ENDPOINT = '/appstore/featured';
const APP_STORE_CONTACT_FORM_ENDPOINT = '/appstore/contact-form';

export class ApplicationNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApplicationNotFoundError.prototype);
  }
}

export const fetchAppstoreApplications = async (): Promise<AppstoreApplicationResponse | null> => {
  // GMRU: GET /appstore/applications
  const response = await API.get<AppstoreApplicationResponse>(APP_STORE_APPLICATIONS_ENDPOINT);

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  return response.body;
};

export const fetchAppstoreApplication = async (id: number): Promise<AppstoreApplicationDetails> => {
  if (isNaN(id)) {
    throw new ApplicationNotFoundError('Invalid application id');
  }

  // GMRU: GET /appstore/applications/id/{id}
  const response = await API.get<AppstoreApplicationDetails>(
    `${APP_STORE_APPLICATIONS_ENDPOINT}/id/${id}`
  );

  if (response.status === 400 || response.status === 404) {
    throw new ApplicationNotFoundError(getErrorMessage(response));
  } else if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};

export const uninstallAppstoreApplication = async (companyApplicationId: number): Promise<void> => {
  // GMRU: DELETE /company-applications/{companyApplicationId}
  const response = await API.delete(`${COMPANY_APPLICATIONS_ENDPOINT}/${companyApplicationId}`);

  if (response.status !== 204) {
    throw new Error(getErrorMessage(response));
  }
};

export const fetchFeaturedApplications = async () => {
  // GMRU: GET /appstore/featured
  const response = await API.get<FeaturedApplication[]>(APP_STORE_FEATURED_ENDPOINT);

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};

export const sendAppstoreContactRequest = async (contactForm: SalesContactRequest) => {
  // GMRU: POST /appstore/contact-form
  const response = await API.post(APP_STORE_CONTACT_FORM_ENDPOINT, contactForm);

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }
};

export const uploadFeaturedAppImage = async (
  applicationId: number,
  applicationType: ApplicationType,
  form: FormData
) => {
  // GMRU: POST /appstore/featured/{applicationType}/{applicationId}/image-upload
  const response = await API.post<{ externalUrl: string }>(
    `${APP_STORE_FEATURED_ENDPOINT}/${applicationType}/${applicationId}/image-upload`,
    form,
    { json: false }
  );

  if (response.status !== 201 || !response.body) {
    throw new Error(getErrorMessage(response) || 'Could not upload brand image');
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!.externalUrl;
};

export const fetchFeaturedApplication = async (
  applicationId: number,
  applicationType: ApplicationType
) => {
  // GMRU: GET /appstore/featured/search?applicationId={applicationId}&applicationType={applicationType}
  const response = await API.get<FeaturedApplication>(
    `${APP_STORE_FEATURED_ENDPOINT}/search?applicationId=${applicationId}&applicationType=${applicationType}`
  );

  if (response.status === 404) {
    return undefined;
  } else if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};

export const updateFeaturedApplications = async (featuredApplications: FeaturedApplication[]) => {
  // GMRU: PUT /appstore/featured
  const response = await API.put(APP_STORE_FEATURED_ENDPOINT, featuredApplications);

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }
};

export const fetchGlobalApplicationId = async (applicationId: number) => {
  // GMRU: GET /applications/{applicationId}/gid
  const response = await API.get<{ id: string }>(`/applications/${applicationId}/gid`);

  if (response.status >= 300 || !response.body) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!.id;
};
