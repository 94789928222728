import React from 'react';

import { IntegrationDetailsResponse, IntegrationFieldType, VendorKey } from '../../../types';
import { IntegrationFeature } from '../../IntegrationFeature';
import { IntegrationField } from '../../IntegrationField';
import { SettingsStandard } from '../SettingsStandard';

function OmetriaSettingsFormLegacy({
  onComplete,
}: {
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}) {
  return (
    <SettingsStandard
      vendorKey={VendorKey.VENDOR_OMETRIA}
      onComplete={onComplete}
      fields={[
        IntegrationField.Config({
          name: 'apiKey',
          type: IntegrationFieldType.PASSWORD,
          label: 'API Key',
          required: true,
        }),
        IntegrationField.Config({
          name: 'collection',
          type: IntegrationFieldType.TEXT,
          label: 'Collection',
          required: true,
        }),
        IntegrationField.Config({
          name: 'listId',
          type: IntegrationFieldType.TEXT,
          label: 'List IDs',
          required: true,
        }),
      ]}
      features={[
        IntegrationFeature.Config({
          type: 'POSTBACK_EMAILS',
          label: 'Forward emails',
          subtext: 'Forward emails to Ometria ESP',
        }),
        IntegrationFeature.Config({
          type: 'POST_WITH_PHONE',
          label: 'Forward phone',
          subtext: 'Forward phone to Ometria ESP',
        }),
        IntegrationFeature.Config({
          type: 'FEATURE_POST_SUBSCRIBER_OPT_OUT',
          label: 'Forward opt-outs',
          subtext: 'Forward any opt outs to Ometria',
        }),
      ]}
    />
  );
}

export { OmetriaSettingsFormLegacy };
