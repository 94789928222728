import { CountryCode } from '@attentive/locale-utils';
import { IconName } from '@attentive/picnic';

export enum PartnershipTier {
  NONE = 'NONE',
  ELITE = 'ELITE',
  PREMIER = 'PREMIER',
}

export enum VendorKey {
  AFTERSHIP = 'AFTERSHIP',
  BLUECORE = 'BLUECORE',
  BLUESHIFT_EVENTS = 'BLUESHIFT_EVENTS',
  BRAZE = 'BRAZE',
  BRONTO = 'BRONTO',
  CHEETAHMAIL = 'CHEETAHMAIL',
  CHEETAHMAIL_SETUSER = 'CHEETAHMAIL_SETUSER',
  CONVERSIO = 'CONVERSIO',
  CORDIAL = 'CORDIAL',
  CROWD_TWIST = 'CROWD_TWIST',
  DRIP = 'DRIP',
  EMARSYS = 'EMARSYS',
  FRESHDESK = 'FRESHDESK',
  GORGIAS = 'GORGIAS',
  HUBSPOT = 'HUBSPOT',
  ITERABLE = 'ITERABLE',
  KLAVIYO = 'KLAVIYO',
  KUSTOMER = 'KUSTOMER',
  LISTRAK = 'LISTRAK',
  MAILCHIMP = 'MAILCHIMP',
  MAROPOST = 'MAROPOST',
  MPARTICLE = 'MPARTICLE',
  OMNISEND = 'OMNISEND',
  REJOINER = 'REJOINER',
  RESPONSYS = 'RESPONSYS',
  RETENTION_SCIENCE = 'RETENTION_SCIENCE',
  SAILTHRU = 'SAILTHRU',
  SALESFORCE = 'SALESFORCE',
  SALESFORCE_DATA_EXTENSION = 'SALESFORCE_DATA_EXTENSION',
  SALESFORCE_JOURNEY = 'SALESFORCE_JOURNEY',
  SEGMENT_ANALYTICS = 'SEGMENT_ANALYTICS',
  SEGMENT_ANALYTICS_PG_BRANDS = 'SEGMENT_ANALYTICS_PG_BRANDS',
  SHOPIFY = 'SHOPIFY',
  SILVERPOP = 'SILVERPOP',
  SPROUTS = 'SPROUTS',
  VENDOR_ACTIVECAMPAIGN = 'VENDOR_ACTIVECAMPAIGN',
  VENDOR_ADOBE = 'VENDOR_ADOBE',
  VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE = 'VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE',
  VENDOR_AWEBER = 'VENDOR_AWEBER',
  VENDOR_BAZAARVOICE = 'VENDOR_BAZAARVOICE',
  VENDOR_BIGCOMMERCE = 'VENDOR_BIGCOMMERCE',
  VENDOR_BLOOMREACH = 'VENDOR_BLOOMREACH',
  VENDOR_BLOTOUT_WHITELABEL = 'VENDOR_BLOTOUT_WHITELABEL',
  VENDOR_CHATTER = 'VENDOR_CHATTER',
  VENDOR_CAMPAIGN_MONITOR = 'VENDOR_CAMPAIGN_MONITOR',
  VENDOR_CONSTANT_CONTACT = 'VENDOR_CONSTANT_CONTACT',
  VENDOR_DOTDIGITAL = 'VENDOR_DOTDIGITAL',
  VENDOR_ELOQUA = 'VENDOR_ELOQUA',
  VENDOR_EPSILON = 'VENDOR_EPSILON',
  VENDOR_EXPONEA = 'VENDOR_EXPONEA',
  VENDOR_FACEBOOK_ADS = 'VENDOR_FACEBOOK_ADS',
  VENDOR_FIVETRAN = 'VENDOR_FIVETRAN',
  VENDOR_GLADLY = 'VENDOR_GLADLY',
  VENDOR_GOOGLE_TAG_MANAGER = 'VENDOR_GOOGLE_TAG_MANAGER',
  VENDOR_GOOGLE_CONTENT_API = 'VENDOR_GOOGLE_CONTENT_API',
  VENDOR_GOOGLE_ADS = 'VENDOR_GOOGLE_ADS',
  VENDOR_HELPSCOUT = 'VENDOR_HELPSCOUT',
  VENDOR_INTERCOM = 'VENDOR_INTERCOM',
  VENDOR_LIVEPERSON = 'VENDOR_LIVEPERSON',
  VENDOR_LOYALTYLION = 'VENDOR_LOYALTYLION',
  VENDOR_MAGENTO = 'VENDOR_MAGENTO',
  VENDOR_MAILTRAIN = 'VENDOR_MAILTRAIN',
  VENDOR_META_ADS = 'VENDOR_META_ADS',
  VENDOR_NARVAR = 'VENDOR_NARVAR',
  VENDOR_OLO = 'VENDOR_OLO',
  VENDOR_OMETRIA = 'VENDOR_OMETRIA',
  VENDOR_OPTIMIZELY = 'VENDOR_OPTIMIZELY',
  VENDOR_OPTIMOVE = 'VENDOR_OPTIMOVE',
  VENDOR_PASSKIT = 'VENDOR_PASSKIT',
  VENDOR_PAYTRONIX = 'VENDOR_PAYTRONIX',
  VENDOR_PUNCHH = 'VENDOR_PUNCHH',
  VENDOR_REAMAZE = 'VENDOR_REAMAZE',
  VENDOR_RECHARGE = 'VENDOR_RECHARGE',
  VENDOR_RICHPANEL = 'VENDOR_RICHPANEL',
  VENDOR_SALESFORCE_SERVICE_CLOUD = 'VENDOR_SALESFORCE_SERVICE_CLOUD',
  VENDOR_SATISFILABS = 'VENDOR_SATISFILABS',
  VENDOR_SEGMENT = 'VENDOR_SEGMENT',
  VENDOR_SENDGRID = 'VENDOR_SENDGRID',
  VENDOR_SENDINBLUE = 'VENDOR_SENDINBLUE',
  VENDOR_SENDLANE = 'VENDOR_SENDLANE',
  VENDOR_SMILE_IO = 'VENDOR_SMILE_IO',
  VENDOR_STAMPED = 'VENDOR_STAMPED',
  VENDOR_TALON_ONE = 'VENDOR_TALON_ONE',
  VENDOR_TRAVELCLICK = 'VENDOR_TRAVELCLICK',
  VENDOR_WOOCOMMERCE = 'VENDOR_WOOCOMMERCE',
  VENDOR_YESMAIL = 'VENDOR_YESMAIL',
  VENDOR_YOTPO = 'VENDOR_YOTPO',
  VENDOR_ZETA_GLOBAL = 'VENDOR_ZETA_GLOBAL',
  VENDOR_ZOHO = 'VENDOR_ZOHO',
  WHATCOUNTS = 'WHATCOUNTS',
  ZAIUS = 'ZAIUS',
  ZENDESK = 'ZENDESK',
  VENDOR_WORLD_MARKET = 'VENDOR_WORLD_MARKET',
  VENDOR_POST_PILOT = 'VENDOR_POST_PILOT',
  VENDOR_AIRSHIP = 'VENDOR_AIRSHIP',
  VENDOR_SNOWFLAKE_DATA_SHARE = 'VENDOR_SNOWFLAKE_DATA_SHARE',
}

export enum VendorCategory {
  ANALYTICS = 'Analytics',
  AUTOMATION = 'Automation',
  CUSTOMER_DATA_PLATFORM = 'Customer data platform',
  CUSTOMER_EXPERIENCE = 'Customer service provider',
  ECOMMERCE = 'eCommerce platform',
  EMAIL_SERVICE_PROVIDER = 'Email service provider',
  LOYALTY = 'Loyalty',
  REVIEWS_UGC = 'Reviews / UGC',
  SHIPPING_RETURNS = 'Shipping / Returns',
  OTHER = 'Other',
}

export enum IntegrationFieldType {
  CHECKBOX = 'CHECKBOX',
  INT = 'INT',
  LIST = 'LIST',
  MULTILINE = 'MULTILINE',
  PASSWORD = 'PASSWORD',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
}

export enum IntegrationVisibilityType {
  SUPER_USER = 'SuperUser',
}

export interface HelpLink {
  href: string;
  label: string;
}

interface FieldSubtext {
  [contentLookupKey: string]: string;
}

export interface Vendor {
  logo: string;
  tier?: PartnershipTier;
  category: VendorCategory;
  categories?: ApplicationCategory[];
  distributionStatus?: DistributionStatus;
  companyWebsite?: string;
  jointValueProp?: string;
  infoPagesEnabled?: boolean;
  errorReportingEnabled?: boolean;
  setupComponent?: (
    onComplete?: (integrationDetails: IntegrationDetailsResponse) => void,
    superUserEnabled?: boolean
  ) => React.ReactNode;
  settingsComponent?: (
    onComplete?: (integrationDetails: IntegrationDetailsResponse) => void,
    superUserEnabled?: boolean
  ) => React.ReactNode;
  /** setupComponentInternals: a design-agnostic version of 'setupComponent' useable outside of marketplace */
  setupComponentInternals?: (
    onComplete?: (integrationDetails: IntegrationDetailsResponse) => void,
    additionalAction?: React.ReactNode,
    superUserEnabled?: boolean,
    oAuthState?: string
  ) => React.ReactNode;
  learnSubtext?: string;
  whatYoullNeedItems?: React.ReactNode[];
  associatedAttentiveFeatures?: AssociatedAttentiveFeature[];
  helpLinks?: HelpLink[];
  disconnectText?: string;
  disconnectConfirmationText?: string;
  confirmChangesText?: string;
  fieldSubtexts?: Record<string, FieldSubtext>;
  salesEmail?: string;
  customSuccessToastMessage?: string;
  creativeSpecificLists?: CreativeSpecificDataFormat;
  creativeSpecificTags?: CreativeSpecificDataFormat;
  creativeSpecificSources?: CreativeSpecificDataFormat;
  creativeSpecificData?: CreativeSpecificDataFormat;
  creativeSpecificCampaignId?: CreativeSpecificDataFormat;
  preventDisconnect?: boolean;
}

export type Vendors = {
  [key in VendorKey]: Vendor;
};

export enum ATTENTIVE_FEATURE_TYPES {
  JOURNEYS = 'JOURNEYS',
  SEGMENTS = 'SEGMENTS',
  OFFERS = 'OFFERS',
  SUBSCRIBERS = 'SUBSCRIBERS',
  LIST_GROWTH = 'LIST_GROWTH',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  PRIVACY = 'PRIVACY',
}

export interface AttentiveFeature {
  type: ATTENTIVE_FEATURE_TYPES;
  displayName: string;
  iconName: IconName;
  buttonText?: string;
  buttonHref?: string;
  hasButton?: boolean;
}

export enum ApplicationTag {
  FREE_TRIAL = 'FREE_TRIAL',
  PROMOTIONAL_PRICING = 'PROMOTIONAL_PRICING',
}

export type AttentiveFeatures = {
  [key in ATTENTIVE_FEATURE_TYPES]: AttentiveFeature;
};

export interface AssociatedAttentiveFeature {
  feature: AttentiveFeature;
  description: string;
}

export type IntegrationFormFeatureValues = { [name: string]: string | boolean };
export type IntegrationFormValues = { [name: string]: string | IntegrationFormFeatureValues };

export type IntegrationError = {
  id: number;
  vendor: VendorKey;
  errorType: string;
  generatedAt: string;
};

export interface ApplicationErrorNotification {
  applicationId: number;
  title: string;
  name: string;
  description: string;
  buttonName: string;
  link: string;
}

export type IntegrationStatus = {
  enabled: boolean;
  reason: 'CX_INTEGRATION_ENABLED' | null;
  message: string;
};

export interface InputOption {
  name: string;
  value: string;
}

export type FeatureFieldValue = {
  id: number;
  type: string;
  required: boolean;
  displayName: string;
  subtext: string;
  placeholder: string;
  groupLabel?: string;
  inputOptionsList?: InputOption[];
};

export type Feature = {
  externalVendorFeatureId: number;
  type:
    | 'POSTBACK_EMAILS'
    | 'BRIDGE_IDENTIFIERS'
    | 'SYNC_HISTORICAL_PURCHASES'
    | 'CUSTOMER_SERVICE_OPT_IN'
    | 'FORWARD_MESSAGE_CUSTOMER_SERVICE'
    | 'SYNC_THIRD_PARTY_LISTS'
    | 'SYNC_THIRD_PARTY_SEGMENTS'
    | 'FEATURE_UNKNOWN'
    | 'FEATURE_SETUP_REDIRECT_SOURCE'
    | 'FEATURE_ADDITIONAL_SETUP_LINK'
    | 'INGEST_TRANSACTIONAL_EVENTS'
    | 'FEATURE_SYNC_SMS_CONSENT'
    | 'FEATURE_SYNC_EMAIL_CONSENT'
    | 'FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS'
    | 'FEATURE_CREATE_MARKETING_SMS_SUBSCRIPTIONS'
    | 'FEATURE_GENERATE_HOOK'
    | 'FEATURE_POSTBACK_USER_ATTRIBUTES';
};

export type Integration = {
  externalVendorApiId: number;
  companyVendorIntegrationId?: number;
  vendor: VendorKey;
  displayName: string;
  description: string;
  integrationGuideUrl: string | null;
  status: IntegrationStatus;
  visibility: 'ALL' | 'SUPERUSER' | 'NONE' | null;
  features: Feature[];
  totalRecentInstallCount?: number;
};

export type IntegrationVendorsResponse = {
  externalVendorApis: Integration[];
};

export type FeatureDetails = {
  externalVendorFeatureId: number;
  externalVendorFeatureType: string;
  enabled: boolean;
  configuredParameters: {
    [configKey: string]: string | undefined;
  };
};

export type IntegrationConnectionRequest = {
  fields: {
    [name: string]: string | undefined;
  };
};

export type IntegrationConfigurationRequest = {
  fields: {
    [name: string]: string | undefined;
  };
  features?: {
    [type: string]: {
      enabled: boolean;
      fields: {
        [name: string]: string | undefined;
      };
    };
  };
};

export type IntegrationSettingsRequest = {
  fields: {
    [name: string]: string | undefined;
  };
  features?: {
    [type: string]: {
      enabled: boolean;
      fields: {
        [name: string]: string | undefined;
      };
    };
  };
};

export enum IntegrationStatusType {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  CONNECTED = 'CONNECTED',
}

export type IntegrationDetailsResponse = {
  externalVendorApiId: number;
  companyVendorIntegrationId: number;
  configuredParameters: {
    [configKey: string]: string | undefined;
  };
  features: FeatureDetails[];
  status: IntegrationStatusType;
};

export type IntegrationFormErrorResponse = {
  baseError: string | undefined;
  fieldErrors: { [fieldName: string]: string } | undefined;
  featureFieldErrors: { [featureType: string]: { [fieldName: string]: string } } | undefined;
};

export type GoogleTagManagerAccount = {
  accountId: string;
  fingerprint: string;
  name: string;
  path: string;
  shareData: boolean;
  tagManagerUrl: string;
};

export type GoogleTagManagerContainer = {
  accountId: string;
  containerId: string;
  domainName: string[];
  fingerprint: string;
  name: string;
  notes: string;
  path: string;
  publicId: string;
  tagManagerUrl: string;
  usageContext: string[];
};

export type GoogleTagManagerWorkspace = {
  path: string;
  accountId: string;
  containerId: string;
  workspaceId: string;
  name: string;
  description: string;
  fingerprint: string;
  tagManagerUrl: string;
};

export type GoogleContentApiSetupData = {
  merchantIds: number[];
};

export type GoogleAdsAccountData = {
  name: string;
  id: number;
};

export type GoogleAdsSetupData = {
  accounts: GoogleAdsAccountData[];
};

// Public API

export type MarketplaceApplication = AppstoreApplication | Integration;

export interface MarketplaceFeature {
  type: ATTENTIVE_FEATURE_TYPES;
  description: string;
}

export interface DocumentationLink {
  text: string;
  url: string;
}

export enum DistributionStatus {
  PRIVATE = 'private',
  UNLISTED = 'unlisted',
  BETA = 'beta',
  PUBLIC = 'public',
}

export type ApplicationCategory = {
  applicationId?: number;
  name: VendorCategory;
  primary: boolean;
};

export interface AppstoreApplication {
  name: string;
  category?: VendorCategory;
  categories: ApplicationCategory[];
  applicationId: number;
  status: DistributionStatus;
  companyApplicationId: number | null;
  imageUrl: string | null;
  supportEmail: string;
  description: string;
  creatorCompany: string;
  needsUpdate: boolean;
  tier?: PartnershipTier;
  totalInstalls: number;
  totalRecentInstalls: number;
  tags?: string[];
}

export interface AppstoreApplicationDetails extends AppstoreApplication {
  jointValueProp?: string;
  prerequisites: string[];
  companyUrl: string;
  salesEmail?: string;
  installUrl?: string;
  attentiveFeatures: MarketplaceFeature[];
  documentationLinks: DocumentationLink[];
  redirectUrls: string[];
  clientId: string;
}

export interface AppstoreApplicationResponse {
  applications: AppstoreApplication[];
}

export enum ApplicationType {
  INTEGRATION = 'INTEGRATION',
  PUBLIC_API = 'PUBLIC_API',
}

export interface FeaturedApplication {
  applicationType: ApplicationType;
  featuredImageUrl?: string;
  applicationId: number;
  lastUpdated?: string;
}

export interface SalesContactRequest {
  applicationId: number;
  applicationType: ApplicationType;
  fullName: string;
  businessName: string;
  businessEmail: string;
  businessPhone: string;
  websiteUrl: string;
  country?: CountryCode;
  annualRevenueVolume?: string;
  message: string;
  toAddress?: string;
}

export interface FeaturedMarketplaceApplication {
  application: MarketplaceApplication;
  featuredData: FeaturedApplication;
}

export type IntegrationGenerateTokenResponse = {
  token: string;
  end_value: string;
  endType: string;
  tokenType: string;
};

export type IntegrationGenerateAllTokensResponse = {
  [key: string]: IntegrationGenerateTokenResponse;
};

export type CreativeSpecificDataFormat =
  | {
      type: 'verbose';
      key: `${CreativeSpecificDataKeyNames}`;
    }
  | {
      type: 'compact-value';
      key: `${CreativeSpecificDataKeyNames}`;
    }
  | {
      type: 'compact';
      key: `${CreativeSpecificDataKeyNames}`;
    };

export enum CreativeSpecificDataKeyNames {
  LIST = 'creativeSpecificList',
  LISTS = 'creativeSpecificLists',
  DATA = 'creativeSpecificData',
  SOURCES = 'creativeSpecificSources',
  VALUE = 'creativeSpecificSourceValue',
  TAGS = 'creativeSpecificTags',
  CAMPAIGN_ID = 'creativeSpecificCampaignId',
}
