import React from 'react';

import bloomreachLogo from '../assets/vendor_logos/bloomreach.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorBloomreach: Vendor = {
  logo: bloomreachLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  companyWebsite: 'https://www.bloomreach.com/en',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contact data to Bloomreach.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Bloomreach so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Bloomreach account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Bloomreach so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4405769399828',
      label: 'Bloomreach integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_BLOOMREACH}
        onComplete={onComplete}
        connectFields={[
          IntegrationField.Config({
            name: 'projectToken',
            type: IntegrationFieldType.TEXT,
            label: 'Project Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'fqdn',
            type: IntegrationFieldType.TEXT,
            label: 'API Fully-Qualified Domain Name',
            subtext:
              'The full domain, including subdomains, root domain, and top-level domain, of the API endpoint. For example: api.us1.exponea.com (do not include https://)',
            placeholder: 'api.example.com',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'Domain Name',
            placeholder: 'test.example.com',
            required: true,
          }),
          IntegrationField.Config({
            name: 'eventType',
            type: IntegrationFieldType.TEXT,
            label: 'Event Type Label',
            placeholder: 'Attentive Form Submitted',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailIdFieldName',
            type: IntegrationFieldType.TEXT,
            label: 'Email Hard ID',
            subtext:
              'Name of Hard ID for Email Address as configured in Bloomreach. If empty, will default to email_id',
            required: false,
          }),
        ]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward Phone Number',
            subtext:
              'Forward any phone numbers collected by Attentive to Bloomreach using a custom field.',
            initiallyEnabled: true,
            fields: [
              IntegrationField.Config({
                name: 'phoneIdFieldName',
                type: IntegrationFieldType.TEXT,
                label: 'Phone Number Hard ID',
                subtext: 'Name of Hard ID for Phone Number as configured in Bloomreach',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_BLOOMREACH}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'projectToken',
            type: IntegrationFieldType.TEXT,
            label: 'Project Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'fqdn',
            type: IntegrationFieldType.TEXT,
            label: 'API Fully-Qualified Domain Name',
            subtext:
              'The full domain, including subdomains, root domain, and top-level domain, of the API endpoint. For example: api.us1.exponea.com (do not include https://)',
            placeholder: 'api.example.com',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'Domain Name',
            placeholder: 'test.example.com',
            required: true,
          }),
          IntegrationField.Config({
            name: 'eventType',
            type: IntegrationFieldType.TEXT,
            label: 'Event Type Label',
            placeholder: 'Attentive Form Submitted',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailIdFieldName',
            type: IntegrationFieldType.TEXT,
            label: 'Email Hard ID',
            subtext:
              'Name of Hard ID for Email Address as configured in Bloomreach. If empty, will default to email_id',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward Phone Number',
            subtext:
              'Forward any phone numbers collected by Attentive to Bloomreach using a custom field.',
            fields: [
              IntegrationField.Config({
                name: 'phoneIdFieldName',
                type: IntegrationFieldType.TEXT,
                label: 'Phone Number Hard ID',
                subtext: 'Name of Hard ID for Phone Number as configured in Bloomreach',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
