import React from 'react';

import facebookLogo from '../assets/vendor_logos/facebook.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { FacebookAdsSettingsForm } from '../components/SettingsStandard/FacebookAdsSettingsForm';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorFacebookAds: Vendor = {
  logo: facebookLogo,
  category: VendorCategory.OTHER,
  categories: [{ name: VendorCategory.OTHER, primary: true }],
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Sync Attentive segments to Facebook Custom Audiences',
  jointValueProp:
    'With this integration, you can sync Attentive segments to Facebook Custom Audiences.  This allows you to show targeted ads to your subscribers on Facebook as well as create Lookalike Audiences.',
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description: 'Syncs segment data to custom audiences in Facebook Ads Manager',
    },
  ],
  whatYoullNeedItems: ['Administrator access to Facebook Ads Manager'],
  disconnectConfirmationText:
    'Are you sure that you want to disconnect Facebook Ads? If you disconnect this application, Attentive will no longer sync Segments to Facebook Custom Audiences and all mappings will be removed',
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/9626972743060-Facebook-Ads',
      label: 'Set up the Facebook Ads integration',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOauthConnectAndConfigure
        vendorKey={VendorKey.VENDOR_FACEBOOK_ADS}
        connectInstructions="By connecting this integration, you are authorizing Attentive to sync Attentive segments to Facebook Ads. "
        onComplete={onComplete}
        configureFields={[]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_FACEBOOK_ADS}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        form={({ onFormComplete }) => (
          <FacebookAdsSettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
};
